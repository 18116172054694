(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lmsApp')
        .constant('VERSION', "1.4.1")
        .constant('GIT', {
	"message": "FIX: CI",
	"commit": "93d4699c8662913d8d29cfe4fd3e8566de56ef54",
	"branch": "dev",
	"date": "2023-12-27T15:08:27.000Z"
})
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
        .constant('API', {
	"newAdmin": "/adm",
	"course": "/course"
})
        .constant('innopolis', undefined)
        .constant('innopolis_stage', undefined)
        .constant('edu4', undefined)
        .constant('bit', undefined)
;
})();
